<template>
  <base-container>
    <template v-slot:header>
      <v-btn
        class="mx-2"
        @click="$router.replace({ name: 'dashboard' })"
        color="accent"
        >Voltar</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn class="mx-2" @click="newForm" color="accent">Novo</v-btn>
    </template>
    <collection-data-table
      type="animals"
      @item-click="onItemPress"
    ></collection-data-table>
  </base-container>
</template>

<script>
import BaseContainer from "@/components/BaseContainer.vue";
import CollectionDataTable from "@/components/CollectionDataTable";

export default {
  name: "animals",
  components: { BaseContainer, CollectionDataTable },

  methods: {
    onItemPress({ id }) {
      this.$router.push({
        name: "animal-edit",
        params: { type: "edit", docRef: id },
      });
    },

    newForm() {
      this.$router.push({
        name: "animal-edit",
        params: { type: "add" },
      });
    },
  },
};
</script>
